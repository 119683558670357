* {
  margin: 0;
  padding: 0;
}

body {
  /* height: 100vh; */
  /* background-image: linear-gradient(black, red); */
  /* background-color: darkslateblue; */
  font-family: 'poppins';
}
